<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="stopsList"
            sort-by="id"
            class="elevation-1"
            locale="sv"
            disable-pagination
            hide-default-footer
            dense
        >
            <template v-slot:[`item.status`]="{ item }">
                <v-icon
                    v-if="parseInt(item.status) === 0"
                    color="red lighten-1"
                    @click="changeStatus(item, 1)">
                    mdi-close
                </v-icon>
                <v-icon
                    v-else
                    color="success"
                    @click="changeStatus(item, 0)">
                    mdi-check-bold
                </v-icon>
            </template>
            <template v-slot:top>
                <v-toolbar flat dense>
                    <v-toolbar-title>Lista postoi</v-toolbar-title>
                    <v-spacer/>
                    <v-dialog v-model="dialog" max-width="700px">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                tile
                                color="primary"
                                dark
                                small
                                v-on="on"
                                @click="$refs.form ? $refs.form.reset() : null"
                            >
                                <v-icon class="mr-1 mdi-hail">mdi-bus-stop</v-icon> Dodaj postój
                            </v-btn>
                        </template>
                        <v-card>
                            <v-form
                                autocomplete="off"
                                ref="form"
                                v-model="validSaveDataStop"
                                lazy-validation
                                @submit.prevent="save"
                            >
                                <v-card-title>
                                    <span class="headline">Dodaj postój</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="8">
                                                <v-text-field
                                                    v-model="editedItem.name"
                                                    label="Nazwa"
                                                    dense
                                                    :rules="nameRules"
                                                    :error-messages="errors.name"
                                                    outlined
                                                />
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                                <v-switch
                                                    dense
                                                    class="mt-1"
                                                    color="blue"
                                                    v-model="editedItem.status"
                                                    false-value="0"
                                                    true-value="1"
                                                    label="Aktywny"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn
                                        small
                                        outlined
                                        tile
                                        color="error"
                                        @click="close"
                                    >
                                        Anuluj
                                    </v-btn>
                                    <v-btn
                                        tile
                                        small
                                        color="primary"
                                        type="submit">Zapisz</v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-card>
                    </v-dialog>
                    <v-btn
                        to="/"
                        class="ml-3"
                        dark
                        small>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import Vue from "vue";

    export default {
        name: "Stop",
        data: () => ({
            dialog: false,
            stopsList: [],
            headers: [
                { text: 'ID', value: 'id' },
                {
                    text: 'Nazwa',
                    align: 'left',
                    value: 'name',
                },
                { text: 'Status', value: 'status' },
            ],
            editedIndex: -1,
            editedItem: {
                name: '',
                status: 0,
            },
            defaultItem: {
                name: '',
                status: 0,
            },
            errors: {
                name: ''
            },
            nameRules: [v => !!v || 'Pole obowiązkowe!'],
            validSaveDataStop: true
        }),
        // computed: {
        //     stopsMap() {
        //         return this.$store.state.socket.stopsMap
        //     },
        // },
        created() {
            this.getStopList();
        },
        methods: {
            getStopList () {
                this.stopsList = [];

                this.$http.get('p/s/l') // Pobieranie listy postoi
                    .then((response) => {
                        this.stopsList = response.data;
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            title: 'Wystąpił błąd.',
                            text: 'Podczas pobierania listy postoi.'
                        });
                    })
                    .finally(() => {
                        this.search = false;
                    });
            },
            changeStatus (item, newStatus) {
                let editIndex = this.stopsList.indexOf(item);

                if (editIndex > -1) {
                    item.status = newStatus;

                    this.$http.post('p/s/s/' + item.id + '/' + newStatus) //  Zmiana statusu
                        .then((response) => {
                            if (response.status === 0) {
                                this.$notify({
                                    group: 'foo',
                                    type: 'error',
                                    title: 'Wystąpił błąd.',
                                    text: 'Podczas zmiany statusu postoju.'
                                });
                            } else {
                                this.$notify({
                                    group: 'foo',
                                    type: 'success',
                                    title: 'Zaktualizowano status',
                                    text: 'Zaktualizowano status postoju ' + item.name
                                });
                                Object.assign(this.stopsList[editIndex], item);
                                Vue.prototype.$socket.sendObj({"action": "update_4_all_stops"});
                            }
                        })
                        .catch(() => {
                            this.$notify({
                                group: 'foo',
                                type: 'error',
                                title: 'Wystąpił błąd.',
                                text: 'Podczas zmiany statusu postoju.'
                            });
                        });
                }
            },
            close () {
                this.dialog = false;
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
                this.errors = [];
            },
            save () {
                if(this.$refs.form.validate()) {
                    this.editedIndex = this.stopsList.indexOf(this.editedItem);
                    if (this.editedIndex === -1) {
                        this.$http.post('p/s/c', this.editedItem) // Zapis postoju
                            .then(() => {
                                this.errors = [];
                                this.$notify({
                                    group: 'foo',
                                    type: 'success',
                                    text: 'Postój ' + this.editedItem.name + ' został dodany!'
                                });
                                Vue.prototype.$socket.sendObj({"action": "update_4_all_stops"});
                                this.close();
                            })
                            .catch((error) => {
                                this.errors = error.response.data.errors;
                                this.$notify({
                                    group: 'foo',
                                    type: 'error',
                                    title: 'Wystąpił błąd.',
                                    text: 'Podczas dodawania nowego postoju.'
                                });
                            });
                    } else {
                        this.$notify({
                            group: 'foo',
                            type: 'warn',
                            text: 'Nie można edytować postoju.'
                        });
                    }
                } else {
                    this.$notify({
                        group: 'foo',
                        type: 'warn',
                        text: 'Popraw dane w formularzu.'
                    });
                }
            },
        }
    }
</script>
